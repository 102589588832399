import React from 'react';
import underConstruction from "../../assets/construction.jpg";


function ListOfMyCalls() {
    return (
      <div>
          <div style={{ backgroundImage: `url(${underConstruction})`,backgroundRepeat:"no-repeat", backgroundSize:"cover" ,width: '100%', height: window.innerHeight, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>     
           <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: window.innerHeight*1.5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>     

          </div>
         </div>
            {/* <p>list of my calls component</p> */}
      </div>
    )
}

export default ListOfMyCalls;