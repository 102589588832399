import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import { Form } from 'react-bootstrap';
import { cloudStorage, firedb } from '../../firabse/firebaseConfig';
import { localStorageConstants } from '../../constants/constants';
import { IProduct } from '../../interfaces/interfaces';
import ImageUploader from '../internalComponent/ImageUploader';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import short from 'short-uuid';


interface ProfileDescriptionText {
    showTheModal: boolean,
    closeTheModal: (close:boolean)=>void;
  }


const CreateAProduct : React.FC<ProfileDescriptionText> = ({  showTheModal, closeTheModal  }) => {
    const companyId = localStorage.getItem(localStorageConstants.companyId);
    const products = useSelector((state: RootState)=> state.shopState.company.products);
    const shopRef = firedb.doc(`companies/${companyId}`);

    const [nameOfProduct, setNameOfProduct] = useState("");
    const [categoryOfProduct, setCategoryOfProduct] = useState('');
    const [PriceOfProduct, setPriceOfProduct] = useState('');
    const [descriptionOfProduct, setDescriptionOfProduct] = useState('');
    const [imageLinks, setImageLinks] = useState<string[]>([]);

    const addToTheImageList = (newLink:string)=>{
      setImageLinks([...imageLinks, newLink]);
    }
      
    const clearForm = ()=>{
        setNameOfProduct("");
        setCategoryOfProduct("");
        setPriceOfProduct('');
        setDescriptionOfProduct('');
        setImageLinks([]);
    }


    return (
        <Modal
        size="lg"
        style={{width:window.innerWidth*0.95, alignSelf:"center"}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showTheModal}
    

      >
        <Modal.Header closeButton onClick={()=>{
          setCategoryOfProduct('');
          clearForm();
          
          closeTheModal(false) }}  >
          <Modal.Title id="contained-modal-title-vcenter">
             Créer un nouveau produit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
          <Form.Group className="mb-3">
        
          <Form.Label style={{marginTop:20}}>Nom du produit</Form.Label>
          <Form.Control 
                  
                    value={nameOfProduct} 
                    onChange={(e)=>{setNameOfProduct(e.target.value)}} 
                   
                />

          <Form.Label style={{marginTop:20}}>Prix du produit</Form.Label>
          <Form.Control 
                   
                    value={PriceOfProduct} 
                    onChange={(e)=>{setPriceOfProduct(e.target.value)}} 
                   
                />

          <Form.Label style={{marginTop:20}}>Catégorie du produit</Form.Label>
          <Form.Control 
                    value={categoryOfProduct} 
                    onChange={(e)=>{setCategoryOfProduct(e.target.value)}} 
                />

          <Form.Label style={{marginTop:20}}>Description du produit</Form.Label>
          <Form.Control 
                    as="textarea" 
                    value={descriptionOfProduct} 
                    onChange={(e)=>{setDescriptionOfProduct(e.target.value)}} 
                    rows={5} 
                />
              <div>
                <Form.Label style={{marginTop:20}}> Les images du produit </Form.Label>
                  {
                  imageLinks.map(imageLink=>{
                    return (
                     <div>
                           <Image src={imageLink}  style={{width:100, height:100, borderRadius:5}} />
                            <Button variant="danger" style={{alignSelf:"center"}} 
                            onClick={()=>{
                              //                const updatedSupplements = supplements.filter((supplement) => supplement.id !== theChoicearticle.id);
                              const imageRef = cloudStorage.ref(`imageproducts/${companyId}/${imageLink}`);
                              imageRef.delete();
                              const updatedImageLinks = imageLinks.filter((imgLink)=> imgLink !== imageLink );
                              setImageLinks([...updatedImageLinks]);
                              
                            }}
                            >Supprimer l'image</Button>
                     </div> 
                     
                    )
                  }) }
              </div>
            <ImageUploader buttonTitle='ajouter une image' storagePath={`imageproducts/${companyId}`} onImageUploaded={addToTheImageList} />
         
            
          </Form.Group>
         
        </Modal.Body>
        <Modal.Footer>
       
          <Button onClick={async()=>{
                const newProduct : Partial<IProduct> = {
                    id: short.generate(),
                    category : categoryOfProduct ? categoryOfProduct : "",
                    description : descriptionOfProduct ? descriptionOfProduct : "",
                    name : PriceOfProduct ? nameOfProduct : "",
                    price : PriceOfProduct ? PriceOfProduct : "",
                    imagesLinks : imageLinks.length>0 ? imageLinks : []
                }

                const theProducts = products ? [...products, newProduct] : [newProduct];
                shopRef.update({products : theProducts});

                clearForm();
                closeTheModal(false);

          }}>Créer le produit</Button>
        </Modal.Footer>
      </Modal>
    )
}

export default CreateAProduct;