// Home.js
import {useEffect, useState} from 'react';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import africanWelcome from "../../assets/industry.jpeg";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RootState, loginState, setCompany, setCompanyId, setUserId } from '../../redux/store';
import { ExternalRoutes, connectionPage, localStorageConstants } from '../../constants/constants';
import { auth, firedb } from '../../firabse/firebaseConfig';
import { IUser } from '../../interfaces/interfaces';


function ConnectionScreen() {

  const [invalidEmailTEXT, setInvalideEmailTEXT] = useState(" Email invalide, saisissez un email");
  const [connectionImpossibleTEXT, setConnectionImpossibleTEXT] = useState("impossible de se connecter");
  const [userInexistantTEXT, setUserInexistantTEXT] = useState('utilisateur inexistant');
  const [passwordIncorrectTEXT, setPasswordIncorrectTEXT] = useState('mot de passe incorrect');


  const dispatch = useDispatch();
  const localLanguage = useSelector((state: RootState)=> state.shopState.locale);
  const [loadingConnection, setLoadingConnection] = useState(false);
  const navigateTo = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const handleButtonClick = () => {
    setShowAlert(true);
    dispatch(loginState());
    navigateTo(ExternalRoutes.homeScreen);
    // Hide the alert after 3 seconds (adjust the timeout as needed)
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };


const validationSchema = Yup.object({
  //@ts-ignore
  email: Yup.string().email(connectionPage.invalidEmail[localLanguage]).required(connectionPage.filedIsRequired[localLanguage]),
  //@ts-ignore
  password: Yup.string().min(8, connectionPage.invalidPassword[localLanguage] as string).required(connectionPage.filedIsRequired[localLanguage]),
});

useEffect(() => {
  if (showAlert) {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }
}, [showAlert]);

const login = async(email:string, password:string)=>{
  try {
      setLoadingConnection(true);

      await auth.signInWithEmailAndPassword(email, password);
          
      if(auth.currentUser){

          const querySnapShot = await firedb.collection('users').where('email', "==", auth.currentUser.email).get();
          if(!querySnapShot.empty){
              const userData = querySnapShot.docs[0].data() as IUser;
              if(userData.active){
                localStorage.setItem(localStorageConstants.userId, querySnapShot.docs[0].id)
                localStorage.setItem(localStorageConstants.companyId, userData.companyId);
                  
                  dispatch(setCompanyId(userData.companyId));
                  dispatch(setUserId(querySnapShot.docs[0].id));
                 
                  const shopSnapShot = await firedb.doc(`companies/${userData.companyId}`).get();
                     if(shopSnapShot.exists){
                             const RawShopData = shopSnapShot.data() as any;
                           
                             dispatch(setCompany(RawShopData));
                             
                     }
                   
                 dispatch(loginState());

              }
              
              else{
                auth.signOut();
                alert("Compte désactivé")

              
                setLoadingConnection(false);

            }
          }
          
         
          
          
         }

        
  } catch (error) {
      setLoadingConnection(false);
      let errorMessage = connectionImpossibleTEXT;
      switch ((error as any).code) {
          case 'auth/invalid-email':
              errorMessage = invalidEmailTEXT;
            break;
          case 'auth/user-not-found':
              errorMessage = userInexistantTEXT;
            break;

          case 'auth/wrong-password':
              errorMessage = passwordIncorrectTEXT;
            break;
          default:
              errorMessage = connectionImpossibleTEXT;
        }
      alert(`${errorMessage}`);
      console.error('error', error);
  }
}


const formik = useFormik({
  initialValues: {
    email: '',
    password: '',
   
  },
  validationSchema: validationSchema,
  onSubmit: async (values) => {
    // Your existing signUp logic can go here
    const castedValues = validationSchema.cast(values);
    await login(castedValues.email, castedValues.password);
    handleButtonClick();
  },
});

  return (
    <div style={{ backgroundImage: `url(${africanWelcome})`, width: '100%', height: '100vh', display: 'flex', alignItems: 'center', backgroundRepeat:"no-repeat", backgroundSize:"cover",justifyContent: 'center' }}>
    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
     <div style={{flexDirection:"column", width:window.innerWidth*0.8}}>
     <h1 style={{ color: '#ffffff', textAlign:"center", marginRight: 10, marginLeft: 10, flexWrap: 'wrap', alignSelf:"center", marginBottom:30, width:window.innerWidth*0.8 }}>{
      //@ts-ignore
      connectionPage.welcome[localLanguage]
     }</h1>
      
     <Form.Control
          type="text"
          placeholder="email"
          name="email"
          value={formik.values.email.toLowerCase()}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.email && formik.errors.email && <div style={{ color: 'red' }}>{formik.errors.email}</div>}

        <Form.Control
          style={{ marginTop: 30, }}
          type="password"
          //@ts-ignore
          placeholder={connectionPage.passwordPlaceHolder[localLanguage]}
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.password && formik.errors.password && <div style={{ color: 'red' }}>{formik.errors.password}</div>}

        
        {showAlert &&    <Alert  style={{marginTop:15}} variant="warning">
        {
          //@ts-ignore
          connectionPage.accountDoesNotExist[localLanguage]
        }
        </Alert>}
        
        <Button variant='success' style={{width:"60%", marginTop:10, marginLeft:"20%", alignItems:"center", justifyContent:"center", textAlign:"center"}} onClick={()=>{formik.handleSubmit()}}>{
        //@ts-ignore
        connectionPage.button[localLanguage]
      }</Button>
        
        <div style={{alignSelf:"center", justifyContent:"center", textAlign:"center", marginTop:15, marginBottom:15}}>
          <a href={ExternalRoutes.recoverPassword} style={{color:"#ffffff"}}>récupérer le mot de passe</a>  
        </div>

        <div style={{alignSelf:"center", justifyContent:"center", textAlign:"center", marginTop:15}}>
          <a href={ExternalRoutes.signupScreen} style={{color:"#ffffff"}}>créer un compte</a>  
        </div> 

      
      
  
      
     
     </div>
      
       
    </div>
  </div> 
  );
}

export default ConnectionScreen;