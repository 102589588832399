import React from 'react';


function MessageConversationConnected() {
    return (
      <div>

            <p>Message conversation private & connected</p>
      </div>
    )
}

export default MessageConversationConnected;