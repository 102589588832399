import React, { useEffect, useState } from 'react';
import ImageUploader from '../internalComponent/ImageUploader';
import { localStorageConstants } from '../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setEditingProduct} from '../../redux/store';
import { Badge, Button, Card, Carousel, Col, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { firedb } from '../../firabse/firebaseConfig';
import { IProduct } from '../../interfaces/interfaces';
import EditProfileDescription from './EditProfileDescription';
import EditProfileInfos from './EditProfileInfos';
import CreateAProduct from './CreateAProduct';
import EditAProduct from './EditAProduct';
import DeleteConfirmation from './DeleteConfirmation';


function ProfileComponentEdition() {
    
    const shopId = localStorage.getItem(localStorageConstants.companyId);
    const companyInfos = useSelector((state: RootState)=> state.shopState.company);
    const [editingDescription, setEditingDescription] = useState(false);
    const [editingCompanyInfos, setEditingCompanyInfos] = useState(false);
    const [creatingANewProduct, setCreatingANewProduct] = useState(false);
    const [editingAProduct, setEditingAProduct] = useState(false);
    const [deletingAProduct, setDeletingAProduct] = useState(false);
    const [productToDelete, setProductToDelete] = useState<Partial<IProduct>>({});
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    
    const handleLogoImage = async(url:string)=>{
          const shopRef = firedb.doc(`companies/${shopId}`);
          await shopRef.update({logoLink: url}).then(()=>{
            window.location.reload();

          });

     
    }

    const handleCoverImage = async(url:string)=>{
            const shopRef = firedb.doc(`companies/${shopId}`);
           await shopRef.update({coverLink : url}).then(()=>{
            window.location.reload();

           });

    }


    return (
      <div style={{paddingLeft:35}}>
          <Button 
          onClick={()=>{navigateTo(`/myview/${shopId}`)}}
          variant="dark"  style={{ marginLeft: window.innerWidth>500 ? window.innerWidth*0.75 : 80 , marginBottom:50, marginTop:20}}>
          <FontAwesomeIcon icon={faEye} className="mr-5" style={{marginRight:10, color:"#ffffff"}} />

            Voir le profil en tant que visiteur</Button>
            <Col>
            <Row style={{marginTop:5}}>
              <h3 style={{marginLeft:20}}>La photo de profil</h3>
            </Row>
            <Row>
            <div style={{ width: '11em',
                    height: '11em',
                    backgroundSize:"cover",
                    backgroundRepeat:"no-repeat",
                    marginLeft:20,
                    background: `url(${companyInfos.logoLink}) center/cover`,
                    border: '0.1875em solid #f4f4f4',
                    borderRadius: '50%',}}></div>
            </Row>
            <Row>
               <ImageUploader 
             buttonTitle={'Modifier la photo de profil'}
             storagePath={`logos/${shopId}`}  onImageUploaded={handleLogoImage} />

            </Row>
           

            
            </Col>
           
         
          <Col style={{marginTop:50}}>
          <Row >
              <h3 style={{marginLeft:20}}>La photo de couverture</h3>
          </Row>
          <Row style={{marginTop:5}}>
          <div style={{ width: '22em',
                    height: '11em',
                    backgroundSize:"cover",
                    backgroundRepeat:"no-repeat",
                    marginLeft:20,
                    background: `url(${companyInfos.coverLink}) center/cover`,
                    border: '0.1875em solid #f4f4f4'
                  }}></div>
          </Row>

          <Row>
            <ImageUploader 
              buttonTitle={'Modifier la photo de couverture'}
              storagePath={`covers/${shopId}`}  onImageUploaded={handleCoverImage} />

            </Row>
          
          </Col>

          <Col style={{marginTop:50}}>
              <Row>
                  <h3>La description de l'entreprise</h3>
              </Row>
              <Row>
                    <div style={{width: window.innerWidth*0.8, marginLeft:30, borderStyle:"solid", borderWidth:1, borderRadius:5, borderColor:"#f4f4f4"}}>
                          <p>{companyInfos.description}</p>
                    </div>
              </Row>
              <Button variant={"info"} onClick={()=>{setEditingDescription(true)}}>Modifier la description</Button>
             
                <EditProfileDescription  showTheModal={editingDescription} closeTheModal={setEditingDescription} profileTextDescription={companyInfos.description as string}  />
              
          </Col>

          <Col style={{marginTop:50}}>
              <Row>
                  <h3>Les informations de l'entreprise</h3>
              </Row>
              <Row>
                    <div style={{width: window.innerWidth*0.8, marginLeft:20, borderStyle:"solid", borderWidth:1, borderRadius:5, borderColor:"#f4f4f4"}}>
                          <p>Nom de l'entrerpise  : {companyInfos.name ?  companyInfos.name : "Non spécifié"}</p>
                          <p>Activités de l'entrerpise  : {companyInfos.category ?  companyInfos.category : "Non spécifié"}</p>
                          <p>numéro du registre de commerce  : {companyInfos.siret ? companyInfos.siret : "Non spécifié"}</p>
                          <p>Pays de l'entreprise  : {companyInfos.country ? companyInfos.country : "non spécifié"}</p>
                          <p>Adresse de l'entreprise  : {companyInfos.address ? companyInfos.address : "non spécifié"}</p>
                          <p>Numéro de téléphone de l'entrerpise  : {companyInfos.phoneNb ? companyInfos.phoneNb : "non spécifié"}</p>
                    </div>
              </Row>
              <Button variant={"info"}  onClick={()=>setEditingCompanyInfos(true)} >Modifier les informations</Button>
              <EditProfileInfos closeTheModal={setEditingCompanyInfos} showTheModal={editingCompanyInfos}
               
              />
          </Col>


          <Col style={{marginTop:50}}>
                <h3>Les produits de l'entreprise</h3>
                <Button 
          onClick={()=>{setCreatingANewProduct(true)}}
          variant="info"  style={{marginTop:20, marginBottom:20}}>
             <FontAwesomeIcon icon={faPlus} className="mr-5" style={{marginRight:10,  color:"#000000"}} />

            Créer un nouveau produit</Button>

            <CreateAProduct closeTheModal={setCreatingANewProduct} showTheModal={creatingANewProduct} />

            <Row style={{flexDirection:"row"}}>
              {
                
                companyInfos.products && companyInfos.products.map(product=>{
                    return(
                      <Card
                      bg={'Light'}
                      key={product.id}
                      text={product.name}
                      style={{ width: '18rem', marginLeft:15}}
                      className="mb-2"
                    >
                        <Card.Header>{product.name}
                        <Badge bg={"primary"} style={{marginLeft:25}}>{product.category}</Badge>
                        <Badge bg={"info"}  style={{ marginLeft:10}}>{product.price}</Badge>
                          </Card.Header>
                       
                        <Card.Body key={`card-body-${product.id}`}>
                        <Carousel data-bs-theme="dark">
                             {
                              product.imagesLinks.map(imgLink=>{
                                return( 

                                      <Carousel.Item>
                                          <img
                                            className="d-block w-100"
                                            src={imgLink}
                                            key={imgLink}
                                            alt="First slide"
                                            style={{height:250, width:250}}
                                          />
                                    
                                    </Carousel.Item>
                                )
                              })
                             }
                        </Carousel>
                          <Card.Text>
                            {product.description}
                          </Card.Text>

                          <Col>
                            <Button variant={"danger"} onClick={()=>{setProductToDelete(product);  setDeletingAProduct(true)}} style={{marginLeft:10, marginRight:10}}>
                                      Supprimer
                            </Button>

                            <Button variant={"secondary"} onClick={async()=>{
                              
                              dispatch(setEditingProduct(product)) ; 

                              setEditingAProduct(true)}} >
                                      modifier
                            </Button>

                          
                          </Col>
                        </Card.Body>
                    </Card>
                    )
                })

              
              }
            </Row>

            <EditAProduct closeTheModal={setEditingAProduct}  showTheModal={editingAProduct}   />
            
            <DeleteConfirmation showTheModal={deletingAProduct} closeTheModal={setDeletingAProduct}
             confirmDelete={(confirmation: boolean)=>{
                  try {
                        if(confirmation){
                          const updatedProducts = companyInfos.products?.filter((product)=> product.id !== productToDelete.id);
                          const companyRef = firedb.doc(`companies/${shopId}`);
                          companyRef.update({products:updatedProducts});
                        }else{
                            return;
                        }

                       

                  } catch (error) {
                        console.error('error', error);
                        alert('impossible de supprimer le produit séléctionné');
                  }
            }} productName={productToDelete.name ? productToDelete.name : "Le produit sélectionné"} />
              
          </Col>
      </div>
    )
}

export default ProfileComponentEdition;