// Home.js
import {useEffect, useState} from 'react';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import africanWelcome from "../../assets/industry.jpeg";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RootState, loginState, setCompany, setCompanyId, setUserId } from '../../redux/store';
import { ExternalRoutes, connectionPage, localStorageConstants } from '../../constants/constants';
import { auth, firedb } from '../../firabse/firebaseConfig';
import { IUser } from '../../interfaces/interfaces';


function RecoverPasswordScreen() {

  
  const dispatch = useDispatch();
  const localLanguage = useSelector((state: RootState)=> state.shopState.locale);
  const [isSending, setIsSending] = useState(false);

  const navigateTo = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const handleButtonClick = () => {
    setShowAlert(true);
    dispatch(loginState());
    navigateTo(ExternalRoutes.homeScreen);
    // Hide the alert after 3 seconds (adjust the timeout as needed)
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };


const validationSchema = Yup.object({
  //@ts-ignore
  email: Yup.string().email(connectionPage.invalidEmail[localLanguage]).required(connectionPage.filedIsRequired[localLanguage]),
  
});

useEffect(() => {
  if (showAlert) {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }
}, [showAlert]);



const resetPaswword = async(email:string)=>{
    try {
        setIsSending(true);
        auth.sendPasswordResetEmail(email)
        .then(() => {
          setIsSending(false);
         alert('email envoyé, vérifiez vos spams');
        }).then(()=>{
            navigateTo(ExternalRoutes.loginScreen);
        })
        .catch((error) => {
          setIsSending(false);
            alert('utilisateur non reconnu');
            console.error('error', error);
        
        });
          
    } catch (error) {
        console.error('email reset error', error);
        setIsSending(false);
       alert('impossible de recupérer le mail');
    }
}


const formik = useFormik({
  initialValues: {
    email: '',
   
  },
  validationSchema: validationSchema,
  onSubmit: async (values) => {
    // Your existing signUp logic can go here
    const castedValues = validationSchema.cast(values);
    await resetPaswword(castedValues.email);
    handleButtonClick();
  },
});

  return (
    <div style={{ backgroundImage: `url(${africanWelcome})`, width: '100%', height: '100vh', display: 'flex', alignItems: 'center', backgroundRepeat:"no-repeat", backgroundSize:"cover",justifyContent: 'center' }}>
    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
     <div style={{flexDirection:"column", width:window.innerWidth*0.8}}>
     <h3 style={{ color: '#ffffff', textAlign:"center", marginRight: 10, marginLeft: 10, flexWrap: 'wrap', alignSelf:"center", marginBottom:10, width:window.innerWidth*0.8 }}>
      Récupérer 
     </h3>
     <h3 style={{ color: '#ffffff', textAlign:"center", marginRight: 10, marginLeft: 10, flexWrap: 'wrap', alignSelf:"center", marginBottom:30, width:window.innerWidth*0.8 }}>
     le mot de passe     </h3>
      
     <Form.Control
          type="text"
          placeholder="email"
          name="email"
          value={formik.values.email.toLowerCase()}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.email && formik.errors.email && <div style={{ color: 'red' }}>{formik.errors.email}</div>}

       
        
        {showAlert &&    <Alert  style={{marginTop:15}} variant="warning">
        {
          //@ts-ignore
          connectionPage.accountDoesNotExist[localLanguage]
        }
        </Alert>}


       
      <Button variant='success' style={{width:"80%", marginLeft:"10%", marginTop:10, alignSelf:"center", alignItems:"center", justifyContent:"center", textAlign:"center"}} onClick={()=>{formik.handleSubmit()}}>
                 Envoyer le lien par email
      </Button>
 
      
     
     </div>
      
       
    </div>
  </div> 
  );
}

export default RecoverPasswordScreen;