import React from 'react';
import './App.css';
import WrappedApp from './WrappedApp';
import { Provider } from 'react-redux';
import store from './redux/store';




function App() {
  //init firebase
  
  return (
    <Provider store={store}>

        <WrappedApp />
   </Provider>

  );
}

export default App;
