import React from 'react';
import { Button, Modal } from 'react-bootstrap';



interface DeleteConfirmationInterface {
  showTheModal: boolean,
  productName: string,
  confirmDelete : (confirmation: boolean)=> void,
  closeTheModal: (close:boolean)=>void;
}


const DeleteConfirmation :  React.FC<DeleteConfirmationInterface> = ({closeTheModal, confirmDelete, showTheModal, productName})=> {
    return (
      <Modal
      show={showTheModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Etes-vous sûr de vouloir supprimer {productName} ?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{justifyContent:"space-between"}}>
      <Button style={{marginLeft: 20, marginRight: 50}} variant="primary" onClick={()=>{confirmDelete(true); closeTheModal(false);}}>Oui</Button>
        <Button variant="danger" onClick={()=>{confirmDelete(false); closeTheModal(false);}}>Non</Button>
      </Modal.Body>
      
    </Modal>
    )
}

export default DeleteConfirmation;