// Home.js
import React from 'react';

function SupplierList() {
  return (
    <div>
      <h1> SupplierList </h1>
      
    </div>
  );
}

export default SupplierList;