import React, { useState } from 'react';
import { firedb } from '../../firabse/firebaseConfig';
import { localStorageConstants } from '../../constants/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import { Col, Nav, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import underConstruction from "../../assets/construction.jpg";

function WelcomePageConnected() {

  const userId = localStorage.getItem(localStorageConstants.userId);
  const company = useSelector((state: RootState)=> state.shopState.company);
    const naivgationConsts = {founisseurs:"founrnisseurs", appelsDoffres:"Appels d'offres"};   
  const navigateTo = useNavigate();

    return (
      <div>


          <div style={{ backgroundImage: `url(${underConstruction})`,backgroundRepeat:"no-repeat", backgroundSize:"cover" ,width: '100%', height: window.innerHeight, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>     
           <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: window.innerHeight*1.5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>     

          </div>
         </div>
         
              {/* <Row style={{width: window.innerWidth*0.9,  marginLeft:window.innerWidth*0.05, height:30, marginBottom:20, marginTop:20, paddingLeft:30,borderStyle:"solid", borderWidth:1, borderColor:"#f4f4f4"}} onClick={()=>{navigateTo(`research/${userId}`)}}>
                <Col className='sm-1' >
                      <FontAwesomeIcon icon={faMagnifyingGlass} className="mr-5" style={{marginRight:10, color:"#444444"}} />

                </Col>
                <Col className='sm-8'> 
                     <p style={{color:"#444444"}}>Recherche ...</p>

                </Col>

             </Row>
      <Tab.Container id="left-tabs-example"  defaultActiveKey={naivgationConsts.founisseurs}>
        <Row >
          <Nav  variant="pills" 
          style={{justifyContent:"center", alignItems:"center", textAlign:"center", backgroundColor:"#f4f4f4", color:"#474747"}}>
            <Col>
                <Nav.Item className="navYellow">
                  <Nav.Link eventKey={naivgationConsts.founisseurs} >{naivgationConsts.founisseurs}</Nav.Link>
                </Nav.Item>
            </Col>
           
           <Col>
              <Nav.Item  className="navYellow">
                  <Nav.Link eventKey={naivgationConsts.appelsDoffres}>{naivgationConsts.appelsDoffres}</Nav.Link>
              </Nav.Item>
           </Col>
         
          </Nav>
        </Row>
        <Row >
          <Tab.Content>
            <Tab.Pane eventKey={naivgationConsts.founisseurs}style={{color:"#000000"}}>First tab content</Tab.Pane>
            <Tab.Pane eventKey={naivgationConsts.appelsDoffres} style={{color:"#000000"}}>Second tab content</Tab.Pane>
          </Tab.Content>
        </Row>

    </Tab.Container> */}

     </div>
    )
}

export default WelcomePageConnected;