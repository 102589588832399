// Home.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import LoaderWaiting from '../internalComponent/LoaderWaiting';
import ifruit from "../../assets/ifruit.png";
import iris from "../../assets/iris.png";
import sim from "../../assets/sim.png";
import wallace from "../../assets/wallace.png";
import metal from "../../assets/metal.png";
import artisanart from "../../assets/artisanart.png";
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setCompany } from '../../redux/store';
import { profilePageTranslate } from '../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';




function SellerPageNotConnected() {



const [pageLoading,setPageLoading] = useState(true);
const navigateTo = useNavigate();
const dispatch = useDispatch();
const { id } = useParams();

const seller = useSelector((state: RootState)=> state.shopState.company);
const localLanguage = useSelector((state: RootState)=> state.shopState.locale);


const suppliers =  [{
  id:"001",
  name: "Iris",
  description : {fr:"Après plus de 18 ans de sa création, IRIS est devenu un précurseur Algérien et Africain de l'industrie de l'électronique grand public et de l'électroménager, mais aussi le leader incontesté du segment TV.",
  en:"After more than 18 years of its creation, IRIS has become an Algerian and African precursor in the consumer electronics and household appliances industry, but also the undisputed leader in the TV segment."      
},
  category: {fr:"Electronique et électroménager", en:"Electronics and household appliances"},
  logoLink : `${iris}`,
  country: {en:"Algeria",fr:"Algérie"}
},
{
id:"002",
name: "Ifruit",
description : {fr:"Leader Algérien de jus de fruits sans arôme artificielle, sans colorant ni conservateur.", en:"Algerian leader in fruit juices without artificial flavors, colorings or preservatives."},
category: {en:"Food",fr:"Alimentation"},
logoLink : `${ifruit}`,
country: {en:"Algeria",fr:"Algérie"}

},
{
id:"003",
name: "Sim",
description : {en:"Industrial semolerie de la Mitidja (SIM) is an Algerian agri-food group specializing in the manufacture of pasta and couscous. Created in 1990, currently number one in the production of pasta and couscous in Algeria.", fr:"Semoulerie industrielle de la Mitidja (SIM) est un groupe agroalimentaire algérien spécialisé dans la fabrication de pâtes alimentaires et couscous. Créée en 1990, actuellement numéro un de la production de pâtes alimentaires et couscous en Algérie."},
category: {en:"Food",fr:"Alimentation"},
logoLink : `${sim}`,
country: {en:"Algeria",fr:"Algérie"}
},
{
id:"004",
name: "Grace wallace",
description : {en:"Maison de Haute Couture created by a Togolese woman of Nigerian origin, who came into fashion 8 years ago and who very quickly built up a clientele through the quality and originality of her creations in a very African traditional-modern style. Creator of the “Kil Fashion by Grace Wallace” label, Grace Wallace is a jovial, enterprising, creative woman who has a real concern for customer satisfaction.", fr:"Maison de Haute Couture crée par une Togolaise d’origine nigériane, venue dans la mode il y a 8 ans et qui s’est très vite faite une clientèle par la qualité et l’originalité de ses créations au style tradi-moderne très africain. Créatrice de la griffe « Kil Fashion by Grace Wallace », Grace Wallace est une femme joviale, entreprenante, créative et qui a un véritable souci de la satisfaction du client."},
category: {en:"Clothing",fr:"Habillement"},
logoLink : `${wallace}`,
country: {en:"Togo",fr:"Togo"}
},
{
id:"005",
name: "Artisan'Art",
description : {en:"The Hermassi craft workshop founded in 1990 in Tunisia is a specialist in Berber and Maghrebi crafts.",fr:"L'atelier Hermassi de l'artisanat fondé depuis 1990 en Tunisie est un spécialiste de l'artisanat berbère et maghrébin."},
category:{ en:"Craftsmanship", fr:"Artisanat"},
logoLink : `${artisanart}`,
country: {en:"Tunisia",fr:"tunisie"}
}
];

useEffect(()=>{

  const supplier = suppliers.find(obj => obj.id === id);

  if(supplier){
    dispatch(setCompany(supplier));
    setPageLoading(false);
  }


}, []);


  return (
    
   <div>
        {
          pageLoading ? 
          <LoaderWaiting />
          :
          <Container fluid>
          <Row className="mb-4" style={coverPictureStyle as any}>
          <Col>
            <div style={coverOverlayStyle as any}></div>
            <div style={profilePictureStyle as any}>
                  
            <div style={{ width: '11em',
                    height: '11em',
                    backgroundColor:"#ffffff",    
                   
                    borderRadius: '50%',}}>
                  <div style={{ width: '11em',
                    height: '11em',
                    backgroundSize:"cover",
                    backgroundRepeat:"no-repeat",
                    background: `url(${seller.logoLink}) center/cover`,
                    border: '0.1875em solid #fff',
                    borderRadius: '50%',}}></div>
                </div>
                  <h2 style={{color:"#ffffff", textAlign:"center"}}>{seller.name}</h2>
            </div>
           
            <div style={supplierInfoStyle as any}>
            
            <Col>
            <Col style={{marginBottom:20}}>
                  <Badge bg="secondary">
                <FontAwesomeIcon icon={faMapMarker} className="mr-5" style={{marginRight:10, color:"#ffffff"}} />

              {
                // @ts-ignore
                seller.country[localLanguage]}
              </Badge>
              
              <Badge style={{marginLeft:20}} bg="info">
                
                  {
                  // @ts-ignore
                  seller.category[localLanguage]}

                  </Badge>
              </Col>
              <Row>
              <Button variant="success" onClick={()=>{navigateTo('/login')}} style={contactButtonStyle}>{
                //@ts-ignore
                profilePageTranslate.button[localLanguage]
              }</Button>
              </Row>
            </Col>

             
            
            </div>
          </Col>
        </Row>
    
        <Row>
          <Col>
            <Card className="mt-4" style={cardStyle}>
              <Card.Body>
                <Card.Title style={cardTitleStyle}>{
                  //@ts-ignore
                  profilePageTranslate.about[localLanguage]
                } {seller.name} :</Card.Title>
                <Card.Text>
                    {
                    //@ts-ignore
                    seller.description[localLanguage]}
                </Card.Text>
               
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div style={{height:"2px", backgroundColor:"#d4d4d4", width: "50%", marginLeft:"25%", marginTop: "5em", alignSelf:"center"}}></div>
        <Row>
          <Col>
            <Card className="mt-4" style={cardStyle}>
              <Card.Body>
                <Card.Title style={{color:"#474747", fontSize:18}}> {
                //@ts-ignore
                seller.name} {
                  //@ts-ignore
                  profilePageTranslate.noPublishes[localLanguage]
                }</Card.Title>
                <Button variant="success" onClick={()=>{navigateTo("/login")}} style={contactButtonStyle}>{
                  //@ts-ignore
                  profilePageTranslate.button[localLanguage]
                }</Button>
               
              </Card.Body>
            </Card>
          </Col>
        </Row> 
      </Container>
        }
   </div>
  );
}


 // Styles
 const coverPictureStyle = {
  position: 'relative',
  height: '28em',
  background: `url(${metal}) center/cover`,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
};

const coverOverlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.6)',
};

const profilePictureStyle = {
  position:"absolute",
  marginLeft: '2em',
  top:"2em",
 
};



const supplierInfoStyle = {
  position: 'absolute',
  bottom: '1.25em',
  right: '1.25em',
  color: '#fff',
};

const cardStyle = {
  border: 'none',
};

const cardTitleStyle = {
  fontSize: '1.5em',
  fontWeight: 'bold',
  marginBottom: '1.25em',
};

const contactButtonStyle = {
  width : "18em"
};

const contactButtonHoverStyle = {
  backgroundColor: '#166fe5',
  borderColor: '#166fe5',
};


// Media Queries
const mediaQueryTablet = '@media (max-width: 768px)';
const mediaQueryMobile = '@media (max-width: 576px)';

const responsiveStyles = {
  // Adjust styles for tablets
  [`${mediaQueryTablet}`]: {
    profilePictureStyle: {
      marginLeft: '1em',
      top: '1.5em',
      width: '8em',
      height: '8em',
    },
  },
  // Adjust styles for mobile devices
  [`${mediaQueryMobile}`]: {
    profilePictureStyle: {
      marginLeft: '0.5em',
      top: '1em',
      width: '6em',
      height: '6em',
    },
  },
};

export default SellerPageNotConnected;