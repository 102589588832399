import InternalNavBar from '../internalComponent/InternalNavBar';
import { Route, Routes } from 'react-router-dom';
import  MessagesListConnected  from './MessagesListConnected';
import MessageConversationConnected  from './MessageConversationConnected';
import WelcomePageConnected from './WelcomePageConnected';
import ProfileConnected from './ProfileConnected';
import ResearchComponent from './ResearchComponent';
import ProfileComponentEdition from './ProfileComponentEdition';
import ListOfMyCalls from './ListOfMyCalls';
import ListOfAnswers from './ListOfMyAnswers';
import HelpPage from './HelpPage';
import { InternalRoutes } from '../../constants/constants';
import ProfileComponentView from './ProfileComponentView';
import LoaderWaiting from '../internalComponent/LoaderWaiting';

function HomePageConnected() {
  return (
    <div>
     
    <InternalNavBar />


    <Routes>
      
       <Route path={InternalRoutes.welcomePage} Component={WelcomePageConnected} />

       <Route path={InternalRoutes.listOfMyMessages} Component={MessagesListConnected}></Route>
      
       <Route path={InternalRoutes.researchPage} Component={ResearchComponent}></Route>

       <Route path={InternalRoutes.profilePageEdition} Component={ProfileComponentEdition}></Route>

       <Route path={InternalRoutes.listOFCalls} Component={ListOfMyCalls}></Route>

       <Route path={InternalRoutes.listOfAnswers} Component={ListOfAnswers}></Route>

       <Route path={InternalRoutes.profilePageView} Component={ProfileComponentView}></Route>

       <Route path={InternalRoutes.helppage} Component={HelpPage}></Route>

      <Route path={InternalRoutes.conversationpage} Component={MessageConversationConnected} />

      <Route path={InternalRoutes.profilecrossroads} Component={ProfileConnected} />

      <Route path="*" Component={LoaderWaiting} />

    </Routes>
   

    </div>
  );
}

export default HomePageConnected;