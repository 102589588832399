import {  createSlice } from "@reduxjs/toolkit";
import { ICompany, IProduct } from "../interfaces/interfaces";
import { localStorageConstants } from "../constants/constants";


export const sellerSlice = createSlice({
   name:"sellerState",
   initialState: {
     companyId: null as (string|null),
     company : {} as Partial<ICompany>,
     categories : [] as any[],
     locale: "fr" as string,
     connected: false,
     userId:localStorage.getItem(localStorageConstants.userId),
     userEmail:"",
     editingProduct:{} as Partial<IProduct>

     
   },
   reducers:{
    
    setEditingProduct : (state, action)=>{
            state.editingProduct = action.payload;
    },

    setUserId: (state, action)=>{
        state.userId = action.payload;
    },

    userEmail: (state, action)=>{
        state.userEmail = action.payload;
    },

    setLocale : (state,action)=>{
        state.locale = action.payload;
    },
    
    setCategories: (state, action)=>{
          state.categories = [...state.categories, action.payload];
    },

    setCompanyId: (state, action)=>{
        state.companyId = action.payload;
    },

    setCompany: (state, action)=>{
            state.company = action.payload;
    },

    loginState : (state)=>{
            state.connected = true;
    },

    logoutState : (state)=>{
        state.userId = null;
        state.connected = false;
    }

   }
  
  });

  
