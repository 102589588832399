import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging';
import 'firebase/compat/storage';


export const firebaseConfig = {
    apiKey: "AIzaSyA292QszvdYiQyc-ASeVpU9nO3AhIh-26s",
    authDomain: "tafrikt-9c31a.firebaseapp.com",
    projectId: "tafrikt-9c31a",
    storageBucket: "tafrikt-9c31a.appspot.com",
    messagingSenderId: "587399810656",
    appId: "1:587399810656:web:20af7319296d1c03b4bd05",
    measurementId: "G-7GPS5KP4MM"
  };
 
 const defaultApp =  firebase.initializeApp(firebaseConfig);


export const auth = firebase.auth();
export const firedb = firebase.firestore();
export const cloudStorage = firebase.storage();



