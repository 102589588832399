import React, { useEffect, useState } from 'react';
import LoaderWaiting from '../internalComponent/LoaderWaiting';
import { Badge, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import {  useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { faMapMarker, faPen } from '@fortawesome/free-solid-svg-icons';
import { localStorageConstants, profilePageTranslate } from '../../constants/constants';
import ProductCard from './ProductCard';



function ProfileComponentView() {

        const [ pageLoading, setPageLoading] = useState(true);
        const navigateTo = useNavigate();
        const shopId = localStorage.getItem(localStorageConstants.companyId);
        const company = useSelector((state: RootState)=> state.shopState.company);
        const localLanguage = useSelector((state: RootState)=> state.shopState.locale);


    useEffect(()=>{
        if(company){
                setPageLoading(false);
        }
    },[])

        

    return (
        <div>
        {
          pageLoading ? 
          <LoaderWaiting />
          :
          <Container fluid>
       
          <Row className="mb-4" style={{
            position: 'relative',
            height: '28em',
            background: `url(${company.coverLink}) center/cover`,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
          }}>
            
          <Col>
            <div style={coverOverlayStyle as any}></div>
            

            <div style={profilePictureStyle as any}>
           
                  
            <div style={{ width: '11em',
                    height: '11em',
                    backgroundColor:"#ffffff",    
                   
                    borderRadius: '50%',}}>
                  <div style={{ width: '11em',
                    height: '11em',
                    backgroundSize:"cover",
                    backgroundRepeat:"no-repeat",
                    background: `url(${company.logoLink}) center/cover`,
                    border: '0.1875em solid #fff',
                    borderRadius: '50%',}}></div>
                </div>
                  <h2 style={{color:"#ffffff", textAlign:"center"}}>{company.name}</h2>
                  
                </div>
           
            <div style={supplierInfoStyle as any}>
            
            <Col>
            <Col style={{marginBottom:20}}>
            <Badge bg="secondary">
                <FontAwesomeIcon icon={faMapMarker} className="mr-5" style={{marginRight:10, color:"#ffffff"}} />

              {
                // @ts-ignore
                company.country}
              </Badge>
              
              <Badge style={{marginLeft:20}} bg="info">
                
                  {
                  // @ts-ignore
                  company.category}

                  </Badge>
              </Col>
              
             
            </Col>

             
            
            </div>
          </Col>
        </Row>
        <Row>
              <Button 
                onClick={()=>{navigateTo(`/myprofile/${shopId}`)}}
                variant="dark"  style={{marginLeft: window.innerWidth>500 ? window.innerWidth*0.75 : 140, marginBottom:40, width: 250, marginTop:20}}>
                    <FontAwesomeIcon icon={faPen} className="mr-5" style={{marginRight:10, color:"#ffffff"}} />
                Modifier le profil </Button>
        </Row>
        <Row>
          <Col>
            <Card className="mt-4" style={cardStyle}>
              <Card.Body>
                <Card.Title style={cardTitleStyle}>{
                 //@ts-ignore
                  profilePageTranslate.about[localLanguage]
                } {company.name} :</Card.Title>
                <Card.Text>
                    {
                    //@ts-ignore
                    company.description}
                </Card.Text>
               
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div style={{height:"2px", backgroundColor:"#d4d4d4", width: "50%", marginLeft:"25%", marginTop: "5em", alignSelf:"center"}}></div>
        <Row>
          <Col>
            <Card className="mt-4" style={cardStyle}>
              <Card.Body>
                <Card.Title style={{color:"#000000", fontSize:18, marginBottom:25}}> 
                <h4>
                Les produits de {
                //@ts-ignore
                company.name} :
                  </h4> </Card.Title>
                  <Row style={{flexDirection:"row"}}>
                      {
                        company.products?.map((product)=>{
                            return(
                                <ProductCard product={product} key={product.id} />
                            )
                        })
                      }
                  </Row>
               
              </Card.Body>
            </Card>
          </Col>
        </Row> 
      </Container>
        }
   </div>
    )
}




 // Styles
 
  
  const coverOverlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.6)',
  };
  
  const profilePictureStyle = {
    position:"absolute",
    marginLeft: '2em',
    top:"2em",
   
  };
  
  
  
  const supplierInfoStyle = {
    position: 'absolute',
    bottom: '1.25em',
    right: '1.25em',
    color: '#fff',
  };
  
  const cardStyle = {
    border: 'none',
  };
  
  const cardTitleStyle = {
    fontSize: '1.5em',
    fontWeight: 'bold',
    marginBottom: '1.25em',
  };
  
  const contactButtonStyle = {
    width : "18em"
  };
  
  const contactButtonHoverStyle = {
    backgroundColor: '#166fe5',
    borderColor: '#166fe5',
  };
  
  
  // Media Queries
  const mediaQueryTablet = '@media (max-width: 768px)';
  const mediaQueryMobile = '@media (max-width: 576px)';
  
  const responsiveStyles = {
    // Adjust styles for tablets
    [`${mediaQueryTablet}`]: {
      profilePictureStyle: {
        marginLeft: '1em',
        top: '1.5em',
        width: '8em',
        height: '8em',
      },
    },
    // Adjust styles for mobile devices
    [`${mediaQueryMobile}`]: {
      profilePictureStyle: {
        marginLeft: '0.5em',
        top: '1em',
        width: '6em',
        height: '6em',
      },
    },
  };
  
export default ProfileComponentView;