export enum localStorageConstants  {

    companyId  = "companyId",
    userId = "userId",
    userEmail = "userEmail",
    connected = "connected"

}



export enum SiteLanguages {
    fr ="fr",
    en ="en"
}



export const connectionPage = {
    welcome: {en:"We are happy to see you again", fr:"Se connecter"},
    firstWelcome:{fr: "Référencez votre entreprise africaine", en:"Welcome to Tafrikt"},
    companyAddressPH : {fr:"Adresse de l'entreprise", en:'Company address'},
    companyAddressRequired:{fr:"Veuillez saisir l'adresse de votre entreprise", en:"Please fill the address of your company"},
    companySiretRequired:{fr:"Vous devez renseigner votre numéro de registre de commerce", en:"you should fill the information about your commercial register number"},
    companySiretPH: {fr:"numéro de registre de commerce", en:"commercial register number"},
    companyNamePlaceHolder:{en:"Company name", fr:"Nom de l'entreprise"},
    passwordPlaceHolder: {en:"Password", fr:"Mot de passe"},
    passwordConfirmationPlaceHolder: {en:"Password confirmation", fr:"confirmation du mot de passe"},
    sponsorCode:{fr:"Code parrain", en:"Sponsor Code"},
    button:{en:"login", fr:"Connexion"},
    subscriptionButton:{en:"Sign up", fr:"S'inscrire"},
    filedIsRequired: {en:"This field is required", fr:"Ce champ est obligatoire"},
    invalidEmail: {en:"Invalid Email", fr:"Adresse email invalide"},
    invalidPassword: {en:"the password must have at least 8 characters" , fr:"le mot de passe doit avoir au minimum 8 caractères"},
    accountDoesNotExist: {en: "This account does not exist in our database", fr:"Ce compte n'existe pas dans notre base de données"},
    CompanyNameRequired:{en:"The company name is required", fr:"Le nom de l'entreprise est obligatoire"},
    passwordsMustMatch:{en:"Passwords must match", fr:"Les mots de passe doivent matcher"}
}

export const quotePage = {
    pitch:{en:"Request a quote from one or more African suppliers", fr:"Demander un devis à un ou plusieurs founnisseurs Africains"},
    button:{en:"Log in", fr:"Je me connecte"}
}

export const asksPage = {
    pitch:{en:"Discover our multiple calls for tenders", fr:"Découvrez nos multiples appels d'offre"},
    button:{en:"Log in", fr:"Je me connecte"}
}


export const profilePageTranslate = {
    country: {en:"country : ", fr:"pays : "},
    category: {en:"category : ", fr:"catégorie : "},
    button:{en:"Contact the seller", fr:"Contacter le fournisseur"},
    about:{en:"About", fr:"A propos de "},
    noPublishes : {en:"has not yet published its products", fr:"n'a pas encore publié ses produits"}

}

export const searchBarTranslate  = {
        button: {en: "See the supplier's page", fr:"Voir la page du fournisseur"},
        country: {en:"country : ", fr:"pays : "},
        category: {en:"category : ", fr:"catégorie : "}
}


export const navBarTranslate = {
    connexion: {en:"Professional space", fr:"Espace commerçant"}

}

export const HomePageTranslate =  {
    backgroundTitle : {en: "Find African companies and products", fr:"Trouvez les produits made in Africa"},
    searchPlaceHolder : {en: "Search...", fr: "Recherche ..."},
    firstCardPitchTitle : {en: "Find what you are looking for in Africa", fr:"Trouvez ce que vous cherchez en Afrique"},
    firstCardPitchDescription : {en: "Our mission is to promote the marketing and promotion of products made in Africa on the continent and internationally. We offer a unique marketplace, exclusively reserved for products manufactured by companies or artisans based in Africa.", fr:"Notre mission est de favoriser la commercialisation et la valorisation des produits made in Africa dans le continent et à l’international. Nous proposons une marketplac eunique, exclusivement réservée aux produits fabriqués par des entreprises ou des artisans basés en Afrique."},
    firstCardButton: {en: "Create a call for tender", fr:"Je crée un appel d'offre"},
    partners :{en:"Our top partners", fr:"Nos top partenaires"},
    categories :{ en:"Our top categories" ,fr:"Nos top catégories"},
    secondCardTitle: {en:"Request a quote from one or more suppliers", fr:"Demander un devis à un ou plusieurs fournisseurs"},
    secondCardButton:{en:"Ask for a quote", fr:"Je demande un devis"}

}

export enum userRoles {
    manager = "manager",
    employee = "employee"
}

export enum InternalRoutes {
    welcomePage = "/",
    listOfMyMessages = "/messages/:id",
    researchPage = "/research/:id",
    profilePageEdition = "/myprofile/:id",
    profilePageView = "/myview/:id",
    listOFCalls = "/mycalls/:id",
    listOfAnswers = "/myapplications/:id",
    helppage = "/helppage/:id",
    conversationpage = "/conversation/:id",
    profilecrossroads = "/profile/:id"
  
  }


  export enum ExternalRoutes {
    homeScreen = "/",
    loginScreen = "/login",
    recoverPassword = "/recoverpassword",
    signupScreen = "/signup",
    quotextcenter = "/quotextcenter",
    searchPage = "/search",
    supplierPage = "/supplier/:id",
    supplierList = "/supplierlist",
    asksPage = "/asks" 
  }