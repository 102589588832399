import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import { Alert, Form } from 'react-bootstrap';
import { cloudStorage, firedb } from '../../firabse/firebaseConfig';
import { localStorageConstants } from '../../constants/constants';
import ImageUploader from '../internalComponent/ImageUploader';
import {  useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface ProfileDescriptionText {
    showTheModal: boolean,
    closeTheModal: (close:boolean)=>void;
  }


const EditAProduct : React.FC<ProfileDescriptionText> = ({  showTheModal,  closeTheModal  }) => {
    const companyId = localStorage.getItem(localStorageConstants.companyId);
    const products = useSelector((state:RootState)=> state.shopState.company.products);
    const shopRef = firedb.doc(`companies/${companyId}`);
    const editableProduct = useSelector((state: RootState)=> state.shopState.editingProduct);


    const [nameOfProduct, setNameOfProduct] = useState(editableProduct.name);
    const [categoryOfProduct, setCategoryOfProduct] = useState(editableProduct.category);
    const [PriceOfProduct, setPriceOfProduct] = useState(editableProduct.price);
    const [descriptionOfProduct, setDescriptionOfProduct] = useState(editableProduct.description);
    const [imageLinks, setImageLinks] = useState<string[]|undefined>(editableProduct.imagesLinks);

    const addToTheImageList = (newLink:string)=>{
      if(imageLinks){
         setImageLinks([...imageLinks, newLink]);

      }else {
            setImageLinks([newLink]);
      }
    }
    
    useEffect(()=>{
          setNameOfProduct(editableProduct.name);
          setCategoryOfProduct(editableProduct.category);
          setPriceOfProduct(editableProduct.price);
          setDescriptionOfProduct(editableProduct.description);
          setImageLinks(editableProduct.imagesLinks);
    },[editableProduct]);


    const clearForm = ()=>{
        setNameOfProduct("");
        setCategoryOfProduct("");
        setPriceOfProduct('');
        setDescriptionOfProduct('');
        setImageLinks([]);
    }


    return (
        <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{width:window.innerWidth*0.95, alignSelf:"center"}}
        show={showTheModal}
    

      >
        <Modal.Header closeButton onClick={()=>{
          setCategoryOfProduct('');
          
          closeTheModal(false) }}  >
          <Modal.Title id="contained-modal-title-vcenter">
             Modifier un produit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
          <Form.Group className="mb-3">
        
          <Form.Label style={{marginTop:20}}>Nom du produit</Form.Label>
          <Form.Control 
                  
                    value={nameOfProduct} 
                    onChange={(e)=>{setNameOfProduct(e.target.value)}} 
                   
                />

          <Form.Label style={{marginTop:20}}>Prix du produit</Form.Label>
          <Form.Control 
                   
                    value={PriceOfProduct} 
                    onChange={(e)=>{setPriceOfProduct(e.target.value)}} 
                   
                />

          <Form.Label style={{marginTop:20}}>Catégorie du produit</Form.Label>
          <Form.Control 
                    value={categoryOfProduct} 
                    onChange={(e)=>{setCategoryOfProduct(e.target.value)}} 
                />

          <Form.Label style={{marginTop:20}}>Description du produit</Form.Label>
          <Form.Control 
                    as="textarea" 
                    value={descriptionOfProduct} 
                    onChange={(e)=>{setDescriptionOfProduct(e.target.value)}} 
                    rows={5} 
                />
              <div>
                <Form.Label style={{marginTop:20}}> Les images du produit </Form.Label>
                  {
          
                 imageLinks && imageLinks.map(imageLink=>{
                    return (
                     <div>
                           <Image src={imageLink}  style={{width:100, height:100, borderRadius:5}} />
                            <Button variant="danger" style={{alignSelf:"center"}} 
                            onClick={async()=>{
                              try {
                                const imageRef = cloudStorage.refFromURL(`${imageLink}`);
                              
                                await imageRef.delete().then(()=>{
                                  const updatedImageLinks = imageLinks.filter((imgLink)=> imgLink !== imageLink );
                                  setImageLinks([...updatedImageLinks]);
                                });
                              
                              } catch (error) {
                                    console.error('error');
                                     <Alert variant="danger">impossible de supprimer l'image</Alert>
                              }
                              
                            
                              
                            }}
                            >Supprimer l'image</Button>
                     </div> 
                     
                    )
                  }) }
              </div>
            <ImageUploader buttonTitle='ajouter une image' storagePath={`imageproducts/${companyId}`} onImageUploaded={addToTheImageList} />
         
            
          </Form.Group>
         
        </Modal.Body>
        <Modal.Footer>
       
          <Button onClick={async()=>{

            try {
              
              if(products){
                const updatedProducts =  products.map((product) =>
                 product.id == editableProduct.id ? 
                   { ...product, 
                     category : categoryOfProduct ? categoryOfProduct : "",
                     description : descriptionOfProduct ? descriptionOfProduct : "",
                     name : PriceOfProduct ? nameOfProduct : "",
                     price : PriceOfProduct ? PriceOfProduct : "",
                     imagesLinks : imageLinks && imageLinks.length>0 ? imageLinks : []
                   } : product
                 );

 
                    await shopRef.update({products : updatedProducts});
               }else{
                     <Alert variant='danger' >impossible de modifer le produit !</Alert>
               }

            } catch (error) {
                console.error('error', error);
            }
                

               

            
                closeTheModal(false);

          }}>Enregistrer les modifications</Button>
        </Modal.Footer>
      </Modal>
    )
}

export default EditAProduct;