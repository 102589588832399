import React from 'react';
import { Badge, Button, Card, Carousel, Col } from 'react-bootstrap';
import { IProduct } from '../../interfaces/interfaces';


interface IProductCardInterface {
    product: IProduct
}
const ProductCard: React.FC<IProductCardInterface> = ({product}) => {
 
        return(
            <Card
            bg={'Light'}
            key={product.id}
            text={product.name}
            style={{ width: '18rem', marginLeft:15}}
            className="mb-2"
          >
              <Card.Header>{product.name}
              <Badge bg={"primary"} style={{marginLeft:25}}>{product.category}</Badge>
              <Badge bg={"info"}  style={{ marginLeft:10}}>{product.price}</Badge>
                </Card.Header>
             
              <Card.Body key={`card-body-${product.id}`}>
              <Carousel data-bs-theme="dark">
                   {
                    product.imagesLinks.map(imgLink=>{
                      return( 

                            <Carousel.Item>
                                <img
                                  className="d-block w-100"
                                  src={imgLink}
                                  key={imgLink}
                                  alt="First slide"
                                  style={{height:250, width:250}}
                                />
                          
                          </Carousel.Item>
                      )
                    })
                   }
              </Carousel>
                <Card.Text>
                  {product.description}
                </Card.Text>

               
              </Card.Body>
          </Card>
        )
    
}

export default ProductCard;