// navbar external 
import { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import tafriktLogo from "../../assets/tafrikt.png";
import Button from 'react-bootstrap/Button';
import { useNavigate  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { logoutState } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { auth } from '../../firabse/firebaseConfig';
import { InternalRoutes, localStorageConstants } from '../../constants/constants';



function InternalNavBar() {

    const navigateTo = useNavigate();
    const userId = localStorage.getItem(localStorageConstants.userId);

    const dispatch = useDispatch();
    const [activeKey, setActiveKey] = useState('home');

    const handleSelect = (selectedKey : any) => {
      setActiveKey(selectedKey);
    };


    const goToMessages = ()=>{
        navigateTo(`messages/${userId}`);
    }


    const goToProfile  = ()=>{
        navigateTo(`profile/${userId}`);
    }

    

const logout = async()=>{

  try {
 
 
  //signout
  await auth.signOut();
 
      
      if(!auth.currentUser){
              localStorage.removeItem(localStorageConstants.companyId);
              localStorage.removeItem(localStorageConstants.userEmail);
              localStorage.removeItem(localStorageConstants.userId);
              localStorage.removeItem(localStorageConstants.connected);
              localStorage.clear();
              dispatch(logoutState());
             
             
              console.log(auth.currentUser ? "connected" : "disconnected");

            

      }
  
  } catch (error) {
      alert(`${error}`);
      return ;
  }
 
}

  return (
    
    <Navbar className="custom-navbar" expand="lg">
      <Container>
        <Nav.Link className='internBarNav' onClick={()=>{navigateTo("/")}}>
        <Navbar.Brand   >
                 <Image src={tafriktLogo} style={{height:70, width:130}} roundedCircle />
                 
        </Navbar.Brand>
        </Nav.Link>
       
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse className="justify-content-end">
        <Nav className="mr-auto" activeKey={activeKey} onSelect={handleSelect}>

        <Nav.Link className='internBarNav' eventKey={"myCompany"} style={{marginLeft:10, marginRight:10, marginBottom:20}} id="button-addon4" onClick={()=>{goToProfile()}} >
          Mon entreprise
        </Nav.Link>
        <Nav.Link className='internBarNav' eventKey={"messages"} style={{marginLeft:10, marginRight:10, marginBottom:20}} id="button-addon4" onClick={()=>{goToMessages()}}>
          Messages
        </Nav.Link>

        <Button variant="danger" style={{marginLeft:10, marginRight:10 , paddingTop:0, paddingBottom:0, maxHeight:42}} id="button-addon3"  onClick={async()=>{ await logout(); navigateTo('/');   }}>
        <FontAwesomeIcon icon={faSignOutAlt} className="mr-5" style={{marginRight:10}} />
           Déconnexion
         </Button>
       


        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

  );
}

export default InternalNavBar;