// Home.js
import { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import africaBackground from "../../assets/industry.jpeg";
import ExternalNavBar from '../internalComponent/ExternalNavBar';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Routes } from 'react-router-dom';
import ConnectionScreen from './ConnectionScreen';
import SearchResultsPage from './SearchResultsPage';
import SellerPageNotConnected from './SellerPageNotConnected';
import SupplierList from './SupplierList';
import { useNavigate } from 'react-router-dom';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
//@ts-ignore
import Slider from 'react-infinite-logo-slider';

import ifruit from "../../assets/ifruit.png";
import iris from "../../assets/iris.png";
import sim from "../../assets/sim.png";
import wallace from "../../assets/wallace.png";
import artisanart from "../../assets/artisanart.png";
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setLocale } from '../../redux/store';
import { ExternalRoutes, HomePageTranslate } from '../../constants/constants';
import SubscriptionScreen from './SubscriptionScreen';
import { Col , Image, Row} from 'react-bootstrap';
import LoaderWaiting from '../internalComponent/LoaderWaiting';
import RecoverPasswordScreen from './RecoverPasswordScreen';



function HomeScreen(){
const localLanguage = useSelector((state: RootState)=> state.shopState.locale);



const brands = [{
  id:"001",
  name: "Iris",
  description : {fr:"Après plus de 18 ans de sa création, IRIS est devenu un précurseur Algérien et Africain de l'industrie de l'électronique grand public et de l'électroménager, mais aussi le leader incontesté du segment TV.",
  en:"After more than 18 years of its creation, IRIS has become an Algerian and African precursor in the consumer electronics and household appliances industry, but also the undisputed leader in the TV segment."      
},
  category: {fr:"Electronique et électroménager", en:"Electronics and household appliances"},
  image : `${iris}`,
  country: {en:"Algeria",fr:"Algérie"}
},
{
id:"002",
name: "Ifruit",
description : {fr:"Leader Algérien de jus de fruits sans arôme artificielle, sans colorant ni conservateur.", en:"Algerian leader in fruit juices without artificial flavors, colorings or preservatives."},
category: {en:"Food",fr:"Alimentation"},
image : `${ifruit}`,
country: {en:"Algeria",fr:"Algérie"}

},
{
id:"003",
name: "Sim",
description : {en:"Industrial semolerie de la Mitidja (SIM) is an Algerian agri-food group specializing in the manufacture of pasta and couscous. Created in 1990, currently number one in the production of pasta and couscous in Algeria.", fr:"Semoulerie industrielle de la Mitidja (SIM) est un groupe agroalimentaire algérien spécialisé dans la fabrication de pâtes alimentaires et couscous. Créée en 1990, actuellement numéro un de la production de pâtes alimentaires et couscous en Algérie."},
category: {en:"Food",fr:"Alimentation"},
image : `${sim}`,
country: {en:"Algeria",fr:"Algérie"}
},
{
id:"004",
name: "Grace wallace",
description : {en:"Maison de Haute Couture created by a Togolese woman of Nigerian origin, who came into fashion 8 years ago and who very quickly built up a clientele through the quality and originality of her creations in a very African traditional-modern style. Creator of the “Kil Fashion by Grace Wallace” label, Grace Wallace is a jovial, enterprising, creative woman who has a real concern for customer satisfaction.", fr:"Maison de Haute Couture crée par une Togolaise d’origine nigériane, venue dans la mode il y a 8 ans et qui s’est très vite faite une clientèle par la qualité et l’originalité de ses créations au style tradi-moderne très africain. Créatrice de la griffe « Kil Fashion by Grace Wallace », Grace Wallace est une femme joviale, entreprenante, créative et qui a un véritable souci de la satisfaction du client."},
category: {en:"Clothing",fr:"Habillement"},
image : `${wallace}`,
country: {en:"Togo",fr:"Togo"}
},
{
id:"005",
name: "Artisan'Art",
description : {en:"The Hermassi craft workshop founded in 1990 in Tunisia is a specialist in Berber and Maghrebi crafts.",fr:"L'atelier Hermassi de l'artisanat fondé depuis 1990 en Tunisie est un spécialiste de l'artisanat berbère et maghrébin."},
category:{ en:"Craftsmanship", fr:"Artisanat"},
image : `${artisanart}`,
country: {en:"Tunisia",fr:"tunisie"}
}
];

  const navigateTo = useNavigate();


  return(
    <div style={{paddingBottom:20}}>

   
    <div style={{ backgroundImage: `url(${africaBackground})`,backgroundRepeat:"no-repeat", backgroundSize:"cover" ,width: '100%', height: window.innerHeight*1.5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>     
     <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: window.innerHeight*1.5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>      
     
      <Col style={{  width: window.innerWidth*0.8, marginTop:-250}}>
      

          <Row style={{justifyContent:"center",  width:window.innerWidth*0.8, alignSelf:"center", textAlign:"center", marginLeft:window.innerWidth*0.1}}>
              <h1 style={{ color: '#ffffff', marginRight: 10, marginLeft: 10, flexWrap: 'wrap', marginBottom:40, fontSize:50 }}>{ 
                  // @ts-ignore
                HomePageTranslate.backgroundTitle[localLanguage]}</h1>
                    <InputGroup className="mb-3" >
                    
                      <Form.Control 
                      // @ts-ignore
                      placeholder={HomePageTranslate.searchPlaceHolder[localLanguage]}
                      onKeyDown={(event)=>{ if(event.key === "Enter") {navigateTo('/search')}}}
                      aria-label="Text input with checkbox" />
                      <Button variant="success"
                    
                      onClick={()=>{navigateTo('/search')}} id="button-addon2" > 
                            <FontAwesomeIcon icon={faSearch}  />
                              
                      </Button>
                    </InputGroup>
          </Row>

          <Row  style={{marginTop:100}}>
            <Slider
                    marginTop="10px"
                    width="300px"
                    duration={40}
                    pauseOnHover={true}
                    blurBorders={false}
                    blurBoderColor={'#fff'}
                >
                    <Slider.Slide>
                        <img src={ifruit} style={{width:75, height:75, borderRadius:20}} alt="any" className='w-36' />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={iris} style={{width:75, height:75, borderRadius:20}} alt="any2" className='w-36' />
                    </Slider.Slide>
                    <Slider.Slide>
                        <img src={sim} style={{width:75, height:75, borderRadius:20}} alt="any3" className='w-36' />
                    </Slider.Slide>
                    <Slider.Slide>
                          <img src={wallace}  style={{width:75, height:75, borderRadius:20}} alt="any3" className='w-36' />
                    </Slider.Slide>
                </Slider>
               
          </Row>

            

       </Col>  
         
      </div>
      </div>



        



    </div>  )
}




function HomepageNonConnectedPreview() {
  const localLanguage = useSelector((state: RootState)=> state.shopState.locale);

  const dispatch = useDispatch();
 useEffect(()=>{
    dispatch(setLocale('fr'));
    // @ts-ignore
 },[])


  return (
    
    <div>
    <ExternalNavBar />

    <Routes>
      
      <Route path={ExternalRoutes.homeScreen}Component={HomeScreen} />

      <Route path={ExternalRoutes.loginScreen} Component={ConnectionScreen} />

      <Route path={ExternalRoutes.searchPage} Component={SearchResultsPage} />

      <Route path={ExternalRoutes.supplierPage} Component={SellerPageNotConnected} />

      <Route path={ExternalRoutes.supplierList} Component={SupplierList} />

      <Route path={ExternalRoutes.recoverPassword} Component={RecoverPasswordScreen} />
      
      <Route path={ExternalRoutes.signupScreen} Component={SubscriptionScreen} />

      <Route path="*" Component={LoaderWaiting} />

    </Routes>

    
   
  </div>
  
  );
}




export default HomepageNonConnectedPreview;