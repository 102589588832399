// navbar external 
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import tafriktLogo from "../../assets/tafrikt.png";
import Button from 'react-bootstrap/Button';
import { useNavigate  } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import english from "../../assets/english.png";
import french from "../../assets/french.png";
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setLocale } from '../../redux/store';
import { ExternalRoutes, SiteLanguages, navBarTranslate } from '../../constants/constants';


function ExternalNavBar() {
  const localLanguage = useSelector((state: RootState)=> state.shopState.locale);

    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    
    const changeLanguage = (lng: string) => {
       dispatch(setLocale(lng));
    };  
    const goToConnect = ()=>{
        navigateTo(ExternalRoutes.loginScreen);
    }

  

  return (
    
    <Navbar className="custom-navbar" expand="lg">
      <Container>
        <Navbar.Brand href="/">
                 <Image src={tafriktLogo} style={{height:70, width:130}} roundedCircle />
                        
        </Navbar.Brand>
        {/* <NavDropdown
              id="nav-dropdown-dark-example"
              style={{color:"#92808B"}}
              title={<span><img src={ localLanguage == SiteLanguages.fr ? french : english} style={{height:25, width:25, marginRight:10}} alt="" /></span>}
              menuVariant="dark"
            >
            
         <NavDropdown.Item onClick={()=>{changeLanguage("en")}}>  <span><img src={english} style={{height:25, width:25, marginRight:10}} alt="" />English</span></NavDropdown.Item>
              <NavDropdown.Item onClick={()=>{changeLanguage('fr')}}>
              <span><img src={french} style={{height:25, width:25, marginRight:10}} alt="" />Français</span>
              </NavDropdown.Item>
            
             
       </NavDropdown> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse className="justify-content-end">
        <Nav className="mr-auto">


        <Button variant="warning" style={{marginLeft:10, marginRight:10, marginBottom:20}} id="button-addon4" onClick={()=>{goToConnect()}}>
          {
            // @ts-ignore
            navBarTranslate.connexion[localLanguage]
          }
        </Button>


        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

  );
}

export default ExternalNavBar;