import  {useEffect, useState} from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import africanWelcome from "../../assets/industry.jpeg";
import * as Yup from 'yup';
import { RootState, loginState, setCompany, setCompanyId, setUserId } from '../../redux/store';
import { connectionPage, localStorageConstants, userRoles } from '../../constants/constants';
import { auth, firedb } from '../../firabse/firebaseConfig';
import { ICompany } from '../../interfaces/interfaces';
import CguComponent from './CguComponent';

function SubscriptionScreen() {
    const dispatch = useDispatch();
    const localLanguage = useSelector((state: RootState)=> state.shopState.locale);
    const [loadingConnection, setLoadingConnection] = useState(false);
    const [showingTheCGU, setShowingTheCGU] = useState(false);
    const navigateTo = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [isChecked, setIsChecked] = useState(false)
    const handleButtonClick = () => {
      setShowAlert(true);
      dispatch(loginState());
      navigateTo("/");
      // Hide the alert after 3 seconds (adjust the timeout as needed)
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    };
  
  
  const validationSchema = Yup.object({
     //@ts-ignore
    address: Yup.string().required(connectionPage.companyAddressRequired[localLanguage]),
    //@ts-ignore
    companyName: Yup.string().required(connectionPage.CompanyNameRequired[localLanguage]),
    //@ts-ignore
    companySiret: Yup.string().required(connectionPage.companySiretRequired[localLanguage]),
    //@ts-ignore
    email: Yup.string().email(connectionPage.invalidEmail[localLanguage]).required(connectionPage.filedIsRequired[localLanguage]),
    //@ts-ignore
    password: Yup.string().min(8, connectionPage.invalidPassword[localLanguage] as string).required(connectionPage.filedIsRequired[localLanguage]),
    //@ts-ignore
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], connectionPage.passwordsMustMatch[localLanguage]).required(connectionPage.passwordsMustMatch[localLanguage]),
  });
  
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  
  
  const  signUp  = async (values:any)=>{
    try {
        setLoadingConnection(true);
        const userCredential =  await auth.createUserWithEmailAndPassword(values.email, values.password);
        if(userCredential){
              const userRecord = await firedb.collection('users').add({email: values.email, name:values.companyName, role: userRoles.manager, active: true});
              if(userRecord) {
                let company : Partial<ICompany> = {
                    address: values.address,
                    name:values.companyName,
                    email:values.email,
                    siret: values.companySiret,
                    subscribed:true,
                    sponsorCode: values.sponsorCode
                   }

                const shopRecord = await firedb.collection('companies').add({...company});
                dispatch(setUserId(userRecord.id));

                if(shopRecord){  
                     localStorage.setItem(localStorageConstants.companyId, shopRecord.id );
                     localStorage.setItem(localStorageConstants.userEmail, values.email);
                     localStorage.setItem(localStorageConstants.userId, userRecord.id);
                     
                  
                     
                    dispatch(setCompanyId(shopRecord.id));
                    dispatch(setCompany(company));
                }
                userRecord.update({companyId: shopRecord.id});
                if(auth.currentUser){
  
                    dispatch(loginState());
                    console.log(auth.currentUser ? "connected" : "disconnected");
                   }
               

               
              }
        }
      } catch (error) {
          console.error('singup error', error);
          setLoadingConnection(false);
          alert(
                "impossible de créer le compte"
          );
      }
}

  const formik = useFormik({
    initialValues: {
      companyName:"",
      address:"",
      email: '',
      companySiret:"",
      password: '',
      sponsorCode:"",
      passwordConfirmation:""
     
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Your existing signUp logic can go here

       // Handle form submission here
       const castValues = validationSchema.cast(values);
       signUp(castValues);
      handleButtonClick();
    },
  });
  
    return (
      <div style={{ backgroundImage: `url(${africanWelcome})`, width: '100%', height: '100vh', display: 'flex', alignItems: 'center', backgroundRepeat:"no-repeat", backgroundSize:"cover",justifyContent: 'center' }}>
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
       <div style={{flexDirection:"column", paddingBottom: 30}}>
       <h1 style={{ color: '#ffffff', marginTop:35, marginRight: 10, marginLeft: 10, flexWrap: 'wrap', marginBottom:30, textAlign:"center"}}>{
        //@ts-ignore
        connectionPage.firstWelcome[localLanguage]
       }</h1>

        <Form.Control
            type="text"
            style={{marginTop:30, width:window.innerWidth*0.8, marginLeft:window.innerWidth*0.1}}
            placeholder={(connectionPage.companyNamePlaceHolder as any)[localLanguage]}
            name="companyName"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.companyName && formik.errors.companyName && <div style={{ color: 'red', marginLeft:window.innerWidth*0.1 }}>{formik.errors.companyName}</div>}
         
          <Form.Control
            type="text"
            style={{marginTop:30, width:window.innerWidth*0.8, marginLeft:window.innerWidth*0.1}}
            placeholder={(connectionPage.companyAddressPH as any)[localLanguage]}
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.address && formik.errors.address && <div style={{ color: 'red', marginLeft:window.innerWidth*0.1 }}>{formik.errors.address}</div>}
         
          <Form.Control
             style={{marginTop:30, width:window.innerWidth*0.8, marginLeft:window.innerWidth*0.1}}
             type="text"
            placeholder={(connectionPage.companySiretPH as any)[localLanguage]}
            name="companySiret"
            value={formik.values.companySiret}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.companySiret && formik.errors.companySiret && <div style={{ color: 'red', marginLeft:window.innerWidth*0.1 }}>{formik.errors.companySiret}</div>}
       <Form.Control
             style={{marginTop:30, width:window.innerWidth*0.8, marginLeft:window.innerWidth*0.1}}
             type="text"
            placeholder="email"
            name="email"
            value={formik.values.email.toLowerCase()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email && <div style={{ color: 'red', marginLeft:window.innerWidth*0.1 }}>{formik.errors.email}</div>}
  
          <Form.Control
             style={{marginTop:30, width:window.innerWidth*0.8, marginLeft:window.innerWidth*0.1}}
             type="password"
            //@ts-ignore
            placeholder={connectionPage.passwordPlaceHolder[localLanguage]}
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password && formik.errors.password && <div style={{ color: 'red', marginLeft:window.innerWidth*0.1 }}>{formik.errors.password}</div>}
  
          <Form.Control
             style={{marginTop:30, width:window.innerWidth*0.8, marginLeft:window.innerWidth*0.1}}
             type="password"
            //@ts-ignore
            placeholder={connectionPage.passwordConfirmationPlaceHolder[localLanguage]}
            name="passwordConfirmation"
            value={formik.values.passwordConfirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && <div style={{ color: 'red', marginLeft:window.innerWidth*0.1 }}>{formik.errors.passwordConfirmation}</div>}

          <Form.Control
             style={{marginTop:30, width:window.innerWidth*0.8, marginLeft:window.innerWidth*0.1}}
            type="text"
            //@ts-ignore
            placeholder={connectionPage.sponsorCode[localLanguage]}
            name="sponsorCode"
            value={formik.values.sponsorCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />


          {showAlert &&    <Alert  style={{marginTop:15}} variant="warning">
          {
            //@ts-ignore
            connectionPage.accountDoesNotExist[localLanguage]
          }
          </Alert>}

          <Row style={{ display: "flex", flexDirection: "row", marginLeft: window.innerWidth*0.1, justifyContent: "center", alignItems: "center" }}>
           <Col sm={1}>
              <Form.Check
                    inline
                    label="1"
                    name="group1"
                    type="checkbox"
                    checked={isChecked}
                    onChange={()=>setIsChecked(!isChecked)}
                    id={`inline-checkbox-1`}
                  />
           </Col>
           
           <Col sm={11}>
             <div style={{ color: "#ffffff", cursor: 'pointer', marginLeft: '10px' }} onClick={() => { setShowingTheCGU(true) }}>
               J'accepte les conditions générales d'utilisation de Tafrikt
             </div>
            
           </Col>
         
            
          </Row>
  
  
         <div style={{flexDirection:"row", alignSelf:"center", textAlign:"center", marginTop:15}}>
            <a href="/login" style={{color:"#ffffff"}}>Vous avez déjà un compte ?</a>
         </div>
            <Button variant='success' disabled={!isChecked} style={{width:"60%", marginTop:15, marginLeft:"20%"}} onClick={()=>{formik.handleSubmit()}}>{
          //@ts-ignore
          connectionPage.subscriptionButton[localLanguage]
        }</Button>

        <CguComponent showModal={showingTheCGU} closeTheModal={setShowingTheCGU} />
      
       
   
        
       
       </div>
        
      </div>
      </div>
    );
}

export default SubscriptionScreen;