import { useEffect, useState } from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import  { RootState, loginState, setCompany } from './redux/store';
import LoaderWaiting from './components/internalComponent/LoaderWaiting';
import HomepageNonConnectedPreview from './components/preAccountComponents/HomepageNonConnectedPreview';
import HomePageConnected from './components/AccountComponents/HomePageConnected';
import { localStorageConstants } from './constants/constants';
import { firedb } from './firabse/firebaseConfig';


function WrappedApp(){

    const [loading, setLoading] = useState<any>(true);
 
    const userId = useSelector((state:RootState)=>state.shopState.userId);
    const companyId = localStorage.getItem(localStorageConstants.companyId);
    const dispatch = useDispatch();
  


    useEffect(() => {
        const getCompanyId = async ()=>{
            
            try {
                if(companyId){
                    firedb.doc(`companies/${companyId}`).onSnapshot((snapshot)=>{
                       const netCompanyData = snapshot.data() as any;
                      
                       dispatch(setCompany(netCompanyData));
                       
                   });
   
               }else{
                   console.error('erreur de connexion');
               }
            } catch (error) {
                console.error('error',error);
            }
            
        
        }
        getCompanyId();
        setLoading(false);
    }, []);

 
    return( 
    <div className="WrappedApp">

                 {
                 loading ?
                 <LoaderWaiting />
                 :!userId ?<HomepageNonConnectedPreview />
                 : <HomePageConnected />
                }
             
    </div>
    )

}
export default WrappedApp;