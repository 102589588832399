// Home.js
import React, { useEffect } from 'react';
import { Badge, Button, Card, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ifruit from "../../assets/ifruit.png";
import iris from "../../assets/iris.png";
import sim from "../../assets/sim.png";
import wallace from "../../assets/wallace.png";
import artisanart from "../../assets/artisanart.png";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { searchBarTranslate } from '../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';


function SearchResultsPage() {
  const localLanguage = useSelector((state: RootState)=> state.shopState.locale);



const navigateTo = useNavigate();
 
const supplierData = [{
  id:"001",
  name: "Iris",
  description : {fr:"Après plus de 18 ans de sa création, IRIS est devenu un précurseur Algérien et Africain de l'industrie de l'électronique grand public et de l'électroménager, mais aussi le leader incontesté du segment TV.",
  en:"After more than 18 years of its creation, IRIS has become an Algerian and African precursor in the consumer electronics and household appliances industry, but also the undisputed leader in the TV segment."      
},
  category: {fr:"Electronique et électroménager", en:"Electronics and household appliances"},
  image : `${iris}`,
  country: {en:"Algeria",fr:"Algérie"}
},
{
id:"002",
name: "Ifruit",
description : {fr:"Leader Algérien de jus de fruits sans arôme artificielle, sans colorant ni conservateur.", en:"Algerian leader in fruit juices without artificial flavors, colorings or preservatives."},
category: {en:"Food",fr:"Alimentation"},
image : `${ifruit}`,
country: {en:"Algeria",fr:"Algérie"}

},
{
id:"003",
name: "Sim",
description : {en:"Industrial semolerie de la Mitidja (SIM) is an Algerian agri-food group specializing in the manufacture of pasta and couscous. Created in 1990, currently number one in the production of pasta and couscous in Algeria.", fr:"Semoulerie industrielle de la Mitidja (SIM) est un groupe agroalimentaire algérien spécialisé dans la fabrication de pâtes alimentaires et couscous. Créée en 1990, actuellement numéro un de la production de pâtes alimentaires et couscous en Algérie."},
category: {en:"Food",fr:"Alimentation"},
image : `${sim}`,
country: {en:"Algeria",fr:"Algérie"}
},
{
id:"004",
name: "Grace wallace",
description : {en:"Maison de Haute Couture created by a Togolese woman of Nigerian origin, who came into fashion 8 years ago and who very quickly built up a clientele through the quality and originality of her creations in a very African traditional-modern style. Creator of the “Kil Fashion by Grace Wallace” label, Grace Wallace is a jovial, enterprising, creative woman who has a real concern for customer satisfaction.", fr:"Maison de Haute Couture crée par une Togolaise d’origine nigériane, venue dans la mode il y a 8 ans et qui s’est très vite faite une clientèle par la qualité et l’originalité de ses créations au style tradi-moderne très africain. Créatrice de la griffe « Kil Fashion by Grace Wallace », Grace Wallace est une femme joviale, entreprenante, créative et qui a un véritable souci de la satisfaction du client."},
category: {en:"Clothing",fr:"Habillement"},
image : `${wallace}`,
country: {en:"Togo",fr:"Togo"}
},
{
id:"005",
name: "Artisan'Art",
description : {en:"The Hermassi craft workshop founded in 1990 in Tunisia is a specialist in Berber and Maghrebi crafts.",fr:"L'atelier Hermassi de l'artisanat fondé depuis 1990 en Tunisie est un spécialiste de l'artisanat berbère et maghrébin."},
category:{ en:"Craftsmanship", fr:"Artisanat"},
image : `${artisanart}`,
country: {en:"Tunisia",fr:"tunisie"}
}
];


  return (
    <div style={{paddingTop:50}}>
      <Container fluid>
      {supplierData.map((supplier, index) => (
        <Card  key={index} className="mb-3" style={{ width:window.innerWidth*0.95}}>
          <Card.Body className="d-flex" style={{justifyContent:"space-between", display:"flex", flexDirection:window.innerWidth<=768 ? "column" :"row", alignItems:"center", paddingLeft:0}}>
          <span className={`flag-icon flag-icon-${supplier.country} mr-3`} />
            <div style={{width:window.innerWidth<=768? "70%" :"15%", marginBottom:window.innerWidth<=768 ? 15:0,  display:"flex", flexDirection:"column"}}>
            <img src={supplier.image} alt={`Logo of ${supplier.name}`} style={{ height:"10rem", width: "100%" , borderRadius:15, marginBottom:10, marginRight:5}} />

            <Button variant="warning" className="ml-auto" onClick={()=>{navigateTo(`/supplier/${supplier.id}`)}} style={{height:65, minWidth:"20%"}}>{
              // @ts-ignore
                searchBarTranslate.button[localLanguage]
            }</Button>
            </div>
          
            <div style={{width:"70%"}}>
              <Card.Title> <h1> {supplier.name}</h1> </Card.Title>
              <Card.Text>
              <Badge bg="secondary">
                <FontAwesomeIcon icon={faMapMarker} className="mr-5" style={{marginRight:10, color:"#ffffff"}} />

              {
                // @ts-ignore
                supplier.country[localLanguage]}
              </Badge>
               
               <Badge style={{marginLeft:20}} bg="info">
                
                   {
                  // @ts-ignore
                  supplier.category[localLanguage]}

                   </Badge>
                 
                
              </Card.Text>
              <Card.Text>
                  {
                // @ts-ignore
                  supplier.description[localLanguage]}
              </Card.Text>

            </div>
            
          </Card.Body>
        </Card>
      ))}
    </Container>
  </div>
  );

}

export default SearchResultsPage;