
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface ICGUInterface {
        showModal: boolean,
        closeTheModal : (close: boolean)=> void;
}
const  CguComponent : React.FC<ICGUInterface> = ({ showModal, closeTheModal})=> {
    return (
        <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
      >
        <Modal.Header closeButton  onClick={()=>{closeTheModal(false)}}>
          <Modal.Title id="contained-modal-title-vcenter">
            CGU Tafrikt
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <div style={{width: window.innerWidth*0.5, marginLeft:20, overflowY:"scroll"}}>
                    
                <h2>Conditions Générales d'Utilisation (CGU) - Plateforme Tafrikt</h2>
                    <br />
                <p>Bienvenue sur la plateforme Tafrikt ! Nous vous remercions de votre intérêt pour nos services. Avant d'utiliser notre plateforme, veuillez lire attentivement les présentes Conditions Générales d'Utilisation (CGU). En accédant à notre plateforme et en l'utilisant, vous acceptez ces CGU dans leur intégralité. Si vous n'êtes pas d'accord avec l'une quelconque des dispositions de ces CGU, veuillez ne pas utiliser notre plateforme.
</p>

                <br />
                <br />
            <h3>1. Définitions</h3>
     
            <br />
            <h4>1.1 Plateforme Tafrikt</h4>
            <p>désigne la plateforme de commerce électronique B2B proposée par Tafrikt permettant aux utilisateurs d'acheter et de vendre des produits.</p>

            <br />
            <h4>1.2 Utilisateur</h4>
            <p>désigne toute personne physique ou morale qui accède à la plateforme Tafrikt et utilise ses services, que ce soit en tant qu'acheteur ou vendeur.</p>

            <br />
            <br />
            <h3>2. Utilisation de la Plateforme</h3>
            <br />
       
            <h4>2.1  Inscription</h4>
            <p>Pour utiliser certains services de la plateforme Tafrikt, vous devrez vous inscrire et créer un compte. Vous garantissez que toutes les informations fournies lors de l'inscription sont exactes, complètes et à jour. Vous êtes responsable de la confidentialité de votre compte et de votre mot de passe.</p>
                    <br />
            <h4>2.2 Utilisation autorisée</h4>
            <p> Vous vous engagez à utiliser la plateforme Tafrikt conformément aux lois en vigueur et aux présentes CGU. Vous vous interdisez notamment d'utiliser la plateforme à des fins illicites, frauduleuses ou nuisibles.
</p>
                <br />

            <h4>2.3 Contenu utilisateur</h4>
            <p>En publiant du contenu sur la plateforme Tafrikt, vous accordez à Tafrikt une licence non exclusive, mondiale, libre de redevance et transférable pour utiliser, reproduire, modifier, distribuer et afficher ce contenu.</p>
            <br />
            <br />
            <h3>3. Transactions commerciales</h3>
            <br />
            <h4>3.1 Transactions entre utilisateurs</h4>
            <p> Les transactions commerciales effectuées sur la plateforme Tafrikt sont régies par les accords conclus entre les utilisateurs. Tafrikt n'est pas partie à ces accords et n'assume aucune responsabilité quant aux produits vendus ou achetés par le biais de la plateforme.</p>

                <br />
            <h4>3.2 Frais et paiements</h4>
            <p>
            Certains services de la plateforme Tafrikt peuvent être soumis à des frais. Vous acceptez de payer tous les frais applicables liés à votre utilisation de ces services.
            </p>
            
            <br />
            <br />

            <h3> 4. Propriété intellectuelle </h3>
            <br />
            <h4>4.1 Droits de propriété</h4>
            <p> Tous les droits de propriété intellectuelle relatifs à la plateforme Tafrikt, y compris mais sans s'y limiter, les droits d'auteur, les marques de commerce et les brevets, sont la propriété de Tafrikt ou de ses concédants de licence.</p>
            <br />
            <h4> 4.2 Utilisation autorisée</h4>
            <p> Vous êtes autorisé à utiliser la plateforme Tafrikt uniquement dans le cadre des services qui y sont proposés. Toute utilisation non autorisée de la plateforme ou de son contenu est strictement interdite.</p>

            <br />
            <br />
            <h3> 5. Limitation de responsabilité</h3>
            <p>
            Dans toute la mesure permise par la loi, Tafrikt décline toute responsabilité quant aux dommages directs, indirects, accessoires, spéciaux, consécutifs ou punitifs résultant de votre utilisation de la plateforme Tafrikt.
            </p>
            <br />
            <br />

            <h3> 6. Modification des CGU </h3>
            <p> Tafrikt se réserve le droit de modifier à tout moment les présentes CGU. Les modifications entreront en vigueur dès leur publication sur la plateforme Tafrikt. Il vous incombe de consulter régulièrement les CGU pour prendre connaissance des éventuelles modifications.
            </p>
            <br />
            <br />

            <h3>7. Dispositions générales</h3>
            <br />
            <h4> 7.1 Intégralité de l'accord</h4>
            <p> Les présentes CGU constituent l'intégralité de l'accord entre vous et Tafrikt concernant votre utilisation de la plateforme Tafrikt.</p>
            <br />
            <h4>
            7.2 Droit applicable et juridiction
            </h4>
            <p> Les présentes CGU sont régies par le droit sénégalais. Tout litige découlant de ces CGU sera soumis à la compétence exclusive des tribunaux compétents.</p>
            <br />
            <br />
            <p> 
En utilisant la plateforme Tafrikt, vous reconnaissez avoir lu, compris et accepté les présentes Conditions Générales d'Utilisation. Si vous avez des questions ou des préoccupations concernant ces CGU, veuillez nous contacter à tech.tafrikt@gmail.com .

Date d'entrée en vigueur: le 16 février 2024</p>


                </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>closeTheModal(false)}>fermer</Button>
        </Modal.Footer>
      </Modal>
    )
}

export default CguComponent;



