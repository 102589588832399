import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { sellerSlice } from "./shopRedux";


export const {setCompany, setCompanyId, setEditingProduct, setCategories, setUserId, setLocale, loginState, logoutState} = sellerSlice.actions;

const rootReducer = combineReducers({

  shopState: sellerSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;


const store = configureStore({
    reducer: rootReducer 
});

 

export default store;