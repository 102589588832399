import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { localStorageConstants } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';

function ProfileConnected() {

  const userId = localStorage.getItem(localStorageConstants.userId);
  const navigateTo = useNavigate();

    return (
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', width: '100%', height: '100vh', display: 'flex',  justifyContent: 'center' }}>
        <div style={{flexDirection:"column"}}>
           
            <Container>
                  <Col style={{width:"100%"}}>
                  <Row style={{width : window.innerWidth*0.8, marginTop:30}}><Button  onClick={()=>{navigateTo(`/myview/${userId}`)}} variant="outline-info">Voir le profil de mon entreprise</Button></Row>
                    <Row style={{width : window.innerWidth*0.8, marginTop:30}}><Button  onClick={()=>{navigateTo(`/myprofile/${userId}`)}} variant="outline-info">Modifier le profil de mon entreprise</Button></Row>
                    <Row style={{width : window.innerWidth*0.8, marginTop:30}}>  <Button  onClick={()=>{navigateTo(`/mycalls/${userId}`)}} variant="outline-info">Voir mes appels d'offres</Button></Row>
                    <Row style={{width : window.innerWidth*0.8, marginTop:30}}>  <Button onClick={()=>{navigateTo(`/myapplications/${userId}`)}} variant="outline-info">Suivre mes candidatures aux appels d'offre</Button></Row>
                    <Row style={{width : window.innerWidth*0.8, marginTop:30}}>  <Button  onClick={()=>{navigateTo(`/helppage/${userId}`)}} variant="outline-info">Aide</Button></Row>

                  </Col>
            </Container>
  
        </div>
      </div>
    )
}

export default ProfileConnected;