import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import { firedb } from '../../firabse/firebaseConfig';
import { localStorageConstants } from '../../constants/constants';

interface ProfileDescriptionText {
    profileTextDescription: string;
    showTheModal: boolean,
    closeTheModal: (close:boolean)=>void;
  }


const EditProfileDescription : React.FC<ProfileDescriptionText> = ({ profileTextDescription, showTheModal, closeTheModal  }) => {
    const companyId = localStorage.getItem(localStorageConstants.companyId);
    const shopRef = firedb.doc(`companies/${companyId}`);

    const [text, setText] = useState(profileTextDescription);
    
    useEffect(()=>{

      setText(profileTextDescription);
    }, [profileTextDescription])


    return (
        <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showTheModal}
        style={{width:window.innerWidth*0.95, alignSelf:"center"}}


      >
        <Modal.Header closeButton onClick={()=>closeTheModal(false)}  >
          <Modal.Title id="contained-modal-title-vcenter">
          Description de l'entreprise
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
          <Form.Group className="mb-3" >
          <Form.Control 
                    as="textarea" 
                    value={text}
                    onChange={(e)=>{setText(e.target.value)}} 
                    rows={5} 
                />
          </Form.Group>
         
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={async()=>{
                shopRef.update({description : text});
                closeTheModal(false);

          }}>Enregistrer la description</Button>
        </Modal.Footer>
      </Modal>
    )
}

export default EditProfileDescription;