import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import { firedb } from '../../firabse/firebaseConfig';
import { localStorageConstants } from '../../constants/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Root } from 'react-dom/client';


interface ProfileDescriptionText {
  
    showTheModal: boolean,
    closeTheModal: (close:boolean)=>void;
  }


const EditProfileInfos : React.FC<ProfileDescriptionText> = ({ showTheModal, closeTheModal  }) => {
    const companyId = localStorage.getItem(localStorageConstants.companyId);
    const shopRef = firedb.doc(`companies/${companyId}`);
    const company = useSelector((state: RootState)=> state.shopState.company);

    const [companyNameText, setCompanyName] = useState(company.name);
    const [companySiretText, setCompanySiret] = useState(company.siret);
    const [companyCountryText, setCompanyCountry] = useState(company.country);
    const [companyAddressText, setCompanyAddress] = useState(company.address);
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState(company.phoneNb);
    const [companyCategoryActivity, setCompanyActivityCategory] = useState(company.category);
      

    useEffect(()=>{

      setCompanyName(company.name);
      setCompanySiret(company.siret);
      setCompanyCountry(company.country);
      setCompanyAddress(company.address);
      setCompanyPhoneNumber(company.phoneNb);
      setCompanyActivityCategory(company.category);


    }, [company])

    return (
        <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showTheModal}
        style={{width:window.innerWidth*0.95, alignSelf:"center"}}

    

      >
        <Modal.Header closeButton onClick={()=>closeTheModal(false)}  >
          <Modal.Title id="contained-modal-title-vcenter">
          Informations de l'entreprise
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
          <Form.Group className="mb-3" >
         
          <Form.Label style={{marginTop:25}}> Le nom de l'entreprise </Form.Label>
          <Form.Control 

                    value={companyNameText} 
                    onChange={(e)=>{setCompanyName(e.target.value)}} 
                />

        <Form.Label  style={{marginTop:25}}> Le registre de commerce de l'entreprise </Form.Label>
        <Form.Control 
                    type='text'
                    value={companySiretText} 
                    onChange={(e)=>{setCompanySiret(e.target.value)}} 
                />

            <Form.Label style={{marginTop:25}} > La catégorie de l'entreprise </Form.Label>
            <Form.Control 
                    value={companyCategoryActivity} 
                    onChange={(e)=>{setCompanyActivityCategory(e.target.value)}} 
                />


             <Form.Label style={{marginTop:25}} > Le pays de l'entreprise </Form.Label>
            <Form.Control 
                    value={companyCountryText} 
                    onChange={(e)=>{setCompanyCountry(e.target.value)}} 
                />


            <Form.Label style={{marginTop:25}}> L'adresse de l'entreprise </Form.Label>
            <Form.Control 
                    value={companyAddressText} 
                    onChange={(e)=>{setCompanyAddress(e.target.value)}} 
                />

            <Form.Label style={{marginTop:25}}> Le numéro de téléphone de l'entreprise </Form.Label>
            <Form.Control 
                    value={companyPhoneNumber} 
                    onChange={(e)=>{setCompanyPhoneNumber(e.target.value)}} 
                />
            
          </Form.Group>
         
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={async()=>{
           
                shopRef.update({
                address: companyAddressText ?  companyAddressText : "",
                name : companyNameText ? companyNameText :"" , 
                siret : companySiretText ? companySiretText : "",
                phoneNb : companyPhoneNumber ? companyPhoneNumber : "",
                country: companyCountryText ? companyCountryText : "",
                category : companyCategoryActivity ? companyCategoryActivity : ""
                 });
                closeTheModal(false);

          }}>Enregistrer la description</Button>
        </Modal.Footer>
      </Modal>
    )
}

export default EditProfileInfos;