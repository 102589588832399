import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { cloudStorage } from '../../firabse/firebaseConfig';
import { ProgressBar, Button, Alert, Form } from 'react-bootstrap';

interface ImageUploaderProps {
    storagePath: string;
    buttonTitle: string;
    onImageUploaded: (imageUrl: string) => void;
  }


const ImageUploader : React.FC<ImageUploaderProps> = ({ storagePath, buttonTitle, onImageUploaded  }) => {
    const [image, setImage] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadError, setUploadError] = useState(false);
  
    //@ts-ignore
    const handleChange = (e) => {
      if (e.target.files[0]) {
        setImage(e.target.files[0]);
      }
    };
  
    const handleUpload = () => {
      if (image) {
        setUploading(true);
        //@ts-ignore
        const uploadTask = cloudStorage.ref(`${storagePath}/${image.name}`).put(image);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(progress);
          },
          (error) => {
            console.error(error);
            setUploading(false);
          },
          () => {
            cloudStorage
              .ref(`${storagePath}`)
              //@ts-ignore
              .child(image.name)
              .getDownloadURL()
              .then((url) => {
                onImageUploaded(url); // Communicate the image URL to the parent component
                setUploadSuccess(true);
                setUploading(false);
              })
              .catch((error) => {
                console.error(error);
                setUploading(false);
              });
          }
        );
      }
    };

return(
    <div>
    
      <Form.Control
       style={{width : window.innerWidth>500 ? window.innerWidth*0.4 : window.innerWidth*0.8}}
      type="file"
        id="custom-file"
        title={buttonTitle}
        onChange={handleChange}
      />
    {!uploadSuccess && image && !uploading && (
        <Button variant="primary" onClick={handleUpload}>
          Télécharger
        </Button>
      )}
      {uploading && <ProgressBar now={progress} label={`${progress}%`} />}
      {uploadSuccess && (
        <Alert variant="success" onClose={() => {setUploadSuccess(false) ; setImage(null);}} dismissible>
          Fichier téléchargé avec succès !
        </Alert>
      )}

    {uploadError && (
        <Alert variant="danger" onClose={() => {setUploadError(false) ; setImage(null);}} dismissible>
          Impossible de télécharger le fichier ! 
        </Alert>
      )}
  </div>
)

}

export default ImageUploader;