import React from 'react';
import { Spinner } from 'react-bootstrap';

function LoaderWaiting() {

return(
    <div style={{height: window.innerHeight, width: window.innerWidth, display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"row"}}>
               <Spinner  as="span" animation="grow" role="status" aria-hidden="true" />
               <Spinner  as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
               <Spinner  as="span" animation="grow" size="sm" role="status" aria-hidden="true" />

   </div>
)

}

export default LoaderWaiting;